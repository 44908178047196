import React from 'react';
import { Link } from 'react-router-dom';

import { useAccountContext } from '../../contexts/AccountContext';
import { useUserContext } from '../../contexts/UserContext';
import { getIconForService } from '../../libs/libIcon';
import { sortBy } from '../../libs/utils';
import ALApp from '../../types/ALApp';
import ALCatagory from '../../types/ALCatagory';
import WDProduct from '../../types/WDProduct';
import Account from '../Layout/Account';
import { useNavItems } from '../Layout/navItems';
import AppLauncherApp from './AppLauncherApp';

interface AppLauncherProps {
  closeAppLauncher: () => void;
  showAppLauncher: boolean;
}

const convertServicesToLauncher = (products?: WDProduct[]): ALApp[] | undefined => {
  const tools = products?.filter((p) => p.isActive).flatMap((p) => p.tools).filter((t) => t.url != null);
  const apps = tools?.map((tool) => {
    const app: ALApp = {
      name: tool.name,
      path: tool.url,
      icon: getIconForService(tool.name, undefined, undefined, 'nav-text')
    };
    return app;
  }).sort(sortBy('name'));
  return apps;
};

const AppLauncher: React.FC<AppLauncherProps> = (props: AppLauncherProps) => {
  const userContext = useUserContext();
  const accountContext = useAccountContext();
  const navItems = useNavItems();

  const serviceApps = convertServicesToLauncher(accountContext.currentAccountProducts); // TODO

  const apps: ALCatagory[] = [
    {
      name: 'Services',
      apps: serviceApps
    }
  ];

  const handleNavigation = (): void => {
    props.closeAppLauncher();
    window.scrollTo({ top: 0 });
  };

  return <>
    <div className='block md:hidden'>
      <h4 className='text-2xl'>Account</h4>
      <Account />
    </div>
    {apps.map((catagory) => {
      const appRows = catagory.apps?.reduce<ALApp[][]>((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 2);

        if (resultArray[chunkIndex] === undefined) {
          resultArray[chunkIndex] = [];
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);

      return <div key={catagory.name} className='mb-3'>
        <h4 className='text-2xl'>{catagory.name}</h4>
        <div>
          {appRows?.map((row, idx) => {
            return <div key={`app-launcher-row-${idx}`} className='flex flex-row mb-1'>
                {row.map((col) => <AppLauncherApp closeAppLauncher={props.closeAppLauncher} app={col} key={col.name} />)}
              </div>;
          })}
        </div>
      </div>;
    })}
    <div className='block md:hidden'>
      <h4 className='text-2xl'>Pages</h4>
      {navItems.map((navItem) => {
        const selected = navItem.exact === true ? (location.pathname === navItem.path) : (location.pathname.includes(navItem.path ?? 'null'));
        const authed = navItem.auth == null ? true : navItem.auth(userContext);
        if (!authed) {
          return <React.Fragment key={navItem.name} />;
        }

        const inside = (
          <div className={`px-2 py-2 ${selected ? 'bg-selected' : ''} rounded-xl hover:bg-background hover:shadow hover:shadow-background2 hover:text-quickNavHover flex flex-row`}>
            <div className={`inline-block ${selected ? 'text-quickNav' : ''}`}><i className={`bi ${navItem.icon}${selected ? '-fill' : ''} text-4xl`} /></div>
            <div className='pl-2 inline-block my-auto align-middle text-foreground'>{navItem.name}</div>
          </div>
        );

        if (navItem.disabled !== true) {
          if (navItem.path != null) {
            return (
              <Link to={navItem.path} key={navItem.name} className={navItem.className} onClick={handleNavigation}>
                {inside}
              </Link>
            );
          } else if (navItem.to != null) {
            return (
              <a href={navItem.to} key={navItem.name} className={navItem.className}>
                {inside}
              </a>
            );
          }
        } else {
          return (
            <div key={navItem.name} className={`px-2 py-2 ${selected ? 'bg-selected' : 'opacity-60'} rounded-xl flex flex-row`}>
              <div className={`inline-block ${selected ? 'text-quickNav' : ''}`}><i className={`bi ${navItem.icon}${selected ? '-fill' : ''} text-4xl`} /></div>
              <div className='pl-2 inline-block my-auto align-middle text-foreground'>{navItem.name}</div>
            </div>
          );
        }
        // return <div key={navItem.name} className={navItem.className}></div>;
        return <React.Fragment key={navItem.name} />;
      })}
    </div>
  </>;
};

export default AppLauncher;
