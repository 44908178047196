/* eslint quote-props: "off" */
import React from 'react';
import { useAuth } from 'react-oidc-context';
import { useParams } from 'react-router-dom';

import CenterLoadingSpinner from '../../components/LoadingSpinner/CenterLoadingSpinner';
import embedSites from '../../data/embed.json';
import Loading from '../Loading';

interface EmbedParams {
  [name: string]: string | undefined;
  readonly site: string;
}

const siteMap: { [name: string]: string } = embedSites;

const Embed: React.FC = () => {
  const auth = useAuth();
  const { site } = useParams<EmbedParams>();

  if (site === undefined) throw Error('Missing Site for Embed');

  if (auth.user == null) {
    return <CenterLoadingSpinner />;
  }

  const url = siteMap[site].replaceAll('{portalToken}', auth.user.access_token);

  return <iframe src={url} className='w-screen lg:w-[calc(100vw-64px)] -h-screen-nav' />;
};

export default Embed;
